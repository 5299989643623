import axios from 'axios'
import {ParamsModel} from 'src/app/modules/common/Model'
import {ExtensionCounterModel} from '../Model'
const API_URL = window.__RUNTIME_CONFIG__.REACT_APP_API_URL

export const GET_EXTENSION_COUNTER = `${API_URL}/extensioncounter`
export const GET_EXTENSION_COUNTER_FILE = `${API_URL}/extensioncounter/import`
export const GET_DATA = `${API_URL}/extensioncounter/sample-file`

export const service = {
  getExtensionCounter: (params: ParamsModel) => {
    return axios.get(GET_EXTENSION_COUNTER, {params})
  },

  addExtensionCounter: (data: any) => {
    return axios.post(GET_EXTENSION_COUNTER, data)
  },

  activateExtensionCounter: (data: Array<string>) => {
    const formData = {
      extensioncounter: data,
    }
    return axios.put(`${GET_EXTENSION_COUNTER}/bulkactive`, formData)
  },

  singleActivateExtensionCounter: (data: Array<string>) => {
    const formData = {
      extensioncounter: [data],
    }
    return axios.put(`${GET_EXTENSION_COUNTER}/bulkactive`, formData)
  },
  singleDeactivateExtensionCounter: (data: Array<string>) => {
    const formData = {
      extensioncounter: [data],
    }
    return axios.put(`${GET_EXTENSION_COUNTER}/bulkinactive`, formData)
  },

  deactivateExtensionCounter: (data: Array<string>) => {
    const formData = {
      extensioncounter: data,
    }
    return axios.put(`${GET_EXTENSION_COUNTER}/bulkinactive`, formData)
  },

  updateExtensionCounter: (body: ExtensionCounterModel, id: string) => {
    return axios.put(`${GET_EXTENSION_COUNTER}/${id}`, body)
  },

  deleteExtensionCounter: (data: ExtensionCounterModel) => {
    return axios.delete(`${GET_EXTENSION_COUNTER}/bulkdelete`, {data})
  },

  //Import File from Pc
  importExtensionCounterFile: (data: any) => {
    return axios.post(GET_EXTENSION_COUNTER_FILE, data)
  },

  getExtensionCounterFile: (params: ParamsModel) => {
    return axios.get(`${GET_EXTENSION_COUNTER}/export`, {params}).then((response) => {
      const blob = new Blob([response?.data])
      const url = window.URL.createObjectURL(blob)
      const link = document.createElement('a')
      link.href = url
      link.setAttribute('download', `extension-counter.csv`) //or any other extension
      document.body.appendChild(link)
      link.click()
    })
  },

  //export template
  exportTemplateFile: () => {
    return axios.get(`${GET_DATA}`).then((response) => {
      // const blob = new Blob([response?.data])
      // const url = window.URL.createObjectURL(blob)
      const url = response?.data?.data?.file
      const link = document.createElement('a')
      link.href = url
      link.setAttribute('download', '') //or any other extension
      document.body.appendChild(link)
      link.click()
    })
  },
}
