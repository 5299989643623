import {Form, Formik} from 'formik'
import {isEmpty} from 'lodash'
import {useEffect, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {toast} from 'react-toastify'
import Modal from 'rsuite/Modal'
import {StateParamsModel} from 'src/app/modules/common/Model'
import * as Yup from 'yup'
import * as memberSubTypeRedux from '../../memberSubType/index'
import * as memberTypeRedux from '../../memberType/index'
import * as members from '../index'

import checkMultiLanguage from 'src/app/modules/common/checkMultiLanguage'
import {ISettingTypeState} from 'src/app/modules/siteSettings/components/settings'
import FormInputMediaManager from 'src/cms/helpers/components/forms/FormInputMediaManager'
import FormSelect from 'src/cms/helpers/components/forms/FormSelect'
import FormTextBox from 'src/cms/helpers/components/forms/FormTextBox'
import FormTinyMce from 'src/cms/helpers/components/forms/FormTinyMce'
import {imageBaseUrl} from 'src/cms/helpers/constants'
type Props = {
  open: boolean
  params?: StateParamsModel
  handleClose: () => void
  actionType: string
  editSelectedData?: any
}
const FORM_VALIDATION = Yup.object().shape({
  name: Yup.string().required('Name is required'),
  memberTypeId: Yup.string().required('Member type is required'),
  memberSubTypeId: Yup.string().required('Member sub type is required'),
  // memberFeaturedId: Yup.string().required('Member feature is required'),
  designation: Yup.string().required('Designation is required'),
  row: Yup.number()
    .positive('Point must be in greater or equal to 1')
    .max(100)
    .required('Point is required'),
})

const FORM_COLLEGE_VALIDATION = Yup.object().shape({
  name: Yup.string().required('Name is required'),
  memberTypeId: Yup.string().required('Member type is required'),
})
const AddMember = ({open, params, handleClose, actionType, editSelectedData}: Props) => {
  const dispatch = useDispatch()
  const {memberfeatured, loading, success} = useSelector((state: any) => state.member)
  const [selectedMember, setSelectedMember] = useState('')

  const {
    data: {memberType},
  } = useSelector((state: any) => state.memberType)

  const {
    data: {memberSubType},
  } = useSelector((state: any) => state.memberSubType)

  useEffect(() => {
    dispatch(memberTypeRedux.actions.getAllMemberType())
    dispatch(members.actions.getFeaturedMember())
  }, [])

  useEffect(() => {
    if (selectedMember) {
      dispatch(memberSubTypeRedux.actions.getAllMemberSubType(selectedMember))
    }
  }, [selectedMember])

  const memberFeaturedOptions = memberfeatured.map((item: {[key: string]: string}) => ({
    label: item.display_name,
    value: item.id,
    systemName: item.system_name,
  }))

  const memberTypeOptions = memberType.map((item: {[key: string]: string}) => ({
    label: item.name,
    value: item.id,
  }))

  const memberSubTypeOptions = memberSubType.map((item: {[key: string]: string}) => ({
    label: item.name,
    value: item.id,
  }))

  useEffect(() => {
    if (success) {
      isEmpty(editSelectedData)
        ? toast.success('Member added successfully')
        : toast.success('Member edited successfully')
      dispatch(members?.actions?.resetMember())
      dispatch(members?.actions.getMember(params))
      handleClose()
    }
  }, [success])
  const settings: ISettingTypeState = useSelector((state: any) => state.settingType)

  const showInterestTitle =
    settings?.data?.setting?.filter((d) => d.name === 'showInterestTitle')[0]?.value === '1'
      ? true
      : false
  const isSingleLanguage = checkMultiLanguage()
  return (
    <div className='modal-container' data-cy='modal-member-container'>
      <Modal
        open={open}
        onClose={handleClose}
        className='w-75'
        backdrop='static'
        keyboard={false}
        enforceFocus={false}
        data-cy='modal'
      >
        <Modal.Header>
          <Modal.Title data-cy='modal-title'>{actionType} Member </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            <Formik
              initialValues={{
                name: '',
                image: '',
                memberTypeId: '',
                memberSubTypeId: '',
                memberFeaturedId: '',
                designation: '',
                row: '',
              }}
              validationSchema={isSingleLanguage ? FORM_COLLEGE_VALIDATION : FORM_VALIDATION}
              onSubmit={(values: any) => {
                if (showInterestTitle) {
                  delete values['memberFeaturedId']
                }
                const formData = {
                  ...values,
                }
                if (!isEmpty(editSelectedData)) {
                  dispatch(members.actions.updateMember(formData, editSelectedData?.id))
                } else {
                  dispatch(members.actions.addMember(formData))
                }
              }}
            >
              {({touched, handleChange, errors, values, setFieldValue}) => {
                useEffect(() => {
                  if (!isEmpty(editSelectedData)) {
                    const fields = [
                      'designation',
                      'designation_np',
                      'additional_information',
                      'additional_information_np',
                      'id',
                      'message',
                      'message_np',
                      'row',
                      'name',
                      'name_np',
                      'image',
                    ]
                    fields.forEach((field) => setFieldValue(field, editSelectedData[field], false))
                    setFieldValue('memberFeaturedId', editSelectedData?.memberFeatured?.id, false)
                    setFieldValue('memberSubTypeId', editSelectedData?.memberSubType?.id, false)
                    setFieldValue(
                      'memberTypeId',
                      editSelectedData?.memberSubType?.memberType?.id ||
                        editSelectedData?.memberType?.id,
                      false
                    )
                    setFieldValue(
                      'status',
                      editSelectedData?.status === true ? 'Active' : 'Inactive',
                      false
                    )
                  }

                  if (actionType === 'Add') {
                    setFieldValue(
                      'memberFeaturedId',
                      memberFeaturedOptions
                        ?.filter((items: any) => items.systemName === 'no')
                        .map((item: any) => item.value)
                        .toString(),
                      false
                    )
                  }
                }, [])

                return (
                  <Form>
                    <div className='card-body border-top pt-5' data-cy='card-member-body'>
                      <div className='row'>
                        <div className='col-md-6 col-xs-12'>
                          <FormTextBox
                            type='text'
                            labelClassName='col-md-12'
                            containerClassName='col-md-12'
                            placeholder='Enter Name'
                            label={isSingleLanguage ? 'Name' : 'Name (EN)'}
                            name='name'
                            onChange={handleChange}
                            errors={errors}
                            touched={touched}
                            required
                            data-cy='add-member-name-text-field'
                          />
                        </div>
                        {!isSingleLanguage && (
                          <div className='col-md-6 col-xs-12'>
                            <FormTextBox
                              type='text'
                              labelClassName='col-md-12'
                              containerClassName='col-md-12'
                              placeholder='Name (NP)'
                              label='Name (NP)'
                              name='name_np'
                              onChange={handleChange}
                              errors={errors}
                              touched={touched}
                              data-cy='add-member-name-np-text-field'
                            />
                          </div>
                        )}

                        <div className='col-md-6 col-xs-12'>
                          <FormTextBox
                            type='text'
                            labelClassName='col-md-12'
                            containerClassName='col-md-12'
                            placeholder='Enter Designation'
                            label={isSingleLanguage ? 'Designation' : 'Designation (EN)'}
                            name='designation'
                            onChange={handleChange}
                            errors={errors}
                            touched={touched}
                            required={isSingleLanguage ? false : true}
                            data-cy='add-member-designation-text-field'
                          />
                        </div>
                        {!isSingleLanguage && (
                          <div className='col-md-6 col-xs-12'>
                            <FormTextBox
                              type='text'
                              labelClassName='col-md-12'
                              containerClassName='col-md-12'
                              placeholder='Enter Designation (NP)'
                              label='Designation (NP)'
                              name='designation_np'
                              onChange={handleChange}
                              errors={errors}
                              touched={touched}
                              data-cy='add-member-designation-np-text-field'
                            />
                          </div>
                        )}

                        <div className='col-md-6 col-xs-12'>
                          <FormSelect
                            labelClassName='col-md-12'
                            containerClassName='col-md-12'
                            placeholder='Please select'
                            label='Member Type'
                            name='memberTypeId'
                            onChange={handleChange}
                            errors={errors}
                            touched={touched}
                            options={memberTypeOptions}
                            required
                            values={values}
                            setFieldValue={setFieldValue}
                            data-cy='add-member-drop-down-field'
                          />
                        </div>
                        {!isEmpty(values.memberTypeId)
                          ? setSelectedMember(values.memberTypeId)
                          : null}

                        <div className='col-md-6 col-xs-12'>
                          <FormSelect
                            labelClassName='col-md-12'
                            containerClassName='col-md-12'
                            placeholder='Please select'
                            label='Member Sub Type'
                            name='memberSubTypeId'
                            onChange={handleChange}
                            errors={errors}
                            touched={touched}
                            options={memberSubTypeOptions}
                            required={isSingleLanguage ? false : true}
                            values={values}
                            setFieldValue={setFieldValue}
                            data-cy='add-member-sub-drop-down-field'
                          />
                        </div>

                        <div className='col-md-6 col-xs-12'>
                          <FormInputMediaManager
                            labelClassName='col-md-12'
                            containerClassName='col-md-12'
                            label={isSingleLanguage ? 'Image ' : 'Image (EN)'}
                            name='image'
                            setFieldValue={setFieldValue}
                            value={values?.image}
                            data-cy='add-member-image-file-field'
                          />

                          {!isEmpty(values?.image) ? (
                            <>
                              <li className='listing'>
                                <div className='thumbImageBlock'>
                                  <button
                                    type='button'
                                    title='Remove'
                                    className='btn thumbImage-remove d-flex align-items-center justify-content-center btn-primary'
                                    onClick={() => {
                                      setFieldValue('image', '')
                                    }}
                                    data-cy='member-remove-thumb-image-button'
                                  >
                                    Delete
                                  </button>

                                  <img
                                    className='thumbImage w-100 h-100'
                                    src={`${imageBaseUrl}/${values?.image}`}
                                    alt=''
                                    data-cy='member-image-button'
                                  />
                                </div>
                              </li>
                            </>
                          ) : null}
                        </div>
                        <div className='col-md-6 col-xs-12'>
                          <FormTextBox
                            type='number'
                            labelClassName='col-md-12'
                            containerClassName='col-md-12'
                            placeholder='Enter Row'
                            label='Row'
                            name='row'
                            onChange={handleChange}
                            errors={errors}
                            touched={touched}
                            required={isSingleLanguage ? false : true}
                            min='0'
                            data-cy='member-row-text-field'
                          />
                        </div>
                        {/* <div className='col-md-6 col-xs-12'></div> */}
                        <div className='col-md-6 col-xs-12' data-cy='member-row-text-editor'>
                          <FormTinyMce
                            containerClassName='col-md-12'
                            label={isSingleLanguage ? 'Message' : 'Message (EN)'}
                            name='message'
                            initialValue={
                              !isEmpty(editSelectedData) ? editSelectedData?.message : ''
                            }
                            handleChange={handleChange}
                            data-cy='member-editor-message'
                          />
                        </div>
                        {!isSingleLanguage && (
                          <div className='col-md-6 col-xs-12' data-cy='member-row-np-text-editor'>
                            <FormTinyMce
                              containerClassName='col-md-12'
                              label='Message (NP)'
                              name='message_np'
                              initialValue={
                                !isEmpty(editSelectedData) ? editSelectedData?.message_np : ''
                              }
                              handleChange={handleChange}
                              data-cy='member-editor-np-message'
                            />
                          </div>
                        )}

                        {!showInterestTitle && !isSingleLanguage && (
                          <div className='col-md-6 col-xs-12'>
                            <FormSelect
                              labelClassName='col-md-12'
                              containerClassName='col-md-12'
                              placeholder='Please select'
                              label='Featured Member'
                              name='memberFeaturedId'
                              onChange={handleChange}
                              errors={errors}
                              touched={touched}
                              options={memberFeaturedOptions}
                              required={isSingleLanguage ? false : true}
                              values={values}
                              setFieldValue={setFieldValue}
                              data-cy='member-feature-member-drop-down'
                            />
                          </div>
                        )}
                        {!showInterestTitle ? (
                          <>
                            <div className='col-md-6 col-xs-12'>
                              <FormTextBox
                                type='text'
                                labelClassName='col-md-12'
                                containerClassName='col-md-12'
                                placeholder='Enter Additional Information'
                                label={
                                  isSingleLanguage
                                    ? 'Additional Information'
                                    : 'Additional Information (EN)'
                                }
                                name='additional_information'
                                onChange={handleChange}
                                errors={errors}
                                touched={touched}
                                data-cy='member-addition-information-field'
                              />
                            </div>
                            {!isSingleLanguage && (
                              <div className='col-md-6 col-xs-12'>
                                <FormTextBox
                                  type='text'
                                  labelClassName='col-md-12'
                                  containerClassName='col-md-12'
                                  placeholder='Enter Additional Information (NP)'
                                  label='Additional Information (NP)'
                                  name='additional_information_np'
                                  onChange={handleChange}
                                  errors={errors}
                                  touched={touched}
                                  data-cy='member-addition-information-field-np'
                                />
                              </div>
                            )}
                          </>
                        ) : (
                          ''
                        )}
                      </div>
                    </div>

                    <div className='d-flex justify-content-end px-9 '>
                      <button
                        type='submit'
                        disabled={loading}
                        className='btn btn-primary btn-sm ms-3'
                        data-cy='modal-submit'
                      >
                        Save
                      </button>
                      <button
                        type='button'
                        onClick={handleClose}
                        className='btn btn-secondary btn-sm ms-3'
                        data-cy='modal-cancel'
                      >
                        Cancel
                      </button>
                    </div>
                  </Form>
                )
              }}
            </Formik>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  )
}
export default AddMember
