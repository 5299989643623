import Pagination from 'rsuite/Pagination'
import Table from 'rsuite/Table'
import { useState, useEffect } from 'react'
import TrashIcon from '@rsuite/icons/Trash'
import { useSelector, useDispatch } from 'react-redux'
import { EmptyObject } from 'chart.js/types/basic'
import IconButton from 'rsuite/IconButton'
import Toggle from 'rsuite/Toggle'
import Tooltip from 'rsuite/Tooltip'
import Whisper from 'rsuite/Whisper'
import Tag from 'rsuite/Tag'
import { AiOutlineEdit } from 'react-icons/ai'
import { toast } from 'react-toastify'
//Manual import
import RSuiteTable from 'src/cms/helpers/components/rsuiteTable'
import { StateParamsModel } from 'src/app/modules/common/Model'
import CheckPermissions from 'src/app/modules/common/checkPermission'
import { ExtensionCounterModel } from '../Model'
import { IExtensionCounterState } from '../redux'
import * as extensionCounterRedux from 'src/app/modules/network/components/extensionCounter/redux'
import DeleteModal from 'src/app/modules/common/components/deleteModal'
import { isEmpty } from 'lodash'
import DesignComponent from 'src/app/modules/common/components/designComponent'
import AddExtensionCounter from './AddExtensionCounter'
import { ISettingTypeState } from 'src/app/modules/siteSettings/components/settings'

const Cell = Table.Cell
const ExtensionCounter = () => {
  const dispatch = useDispatch()
  const [params, setParams] = useState<StateParamsModel>({
    page: 1,
    limit: 10,
  })
  const [allDataParams, setAllDataParams] = useState<StateParamsModel>({
    page: 0,
    limit: 0,
  })

  const [actionType, setActionType] = useState('Add')
  const [alertOpen, setAlertOpen] = useState(false)
  const handleAlertOpen = () => setAlertOpen(true)
  const handleAlertClose = () => setAlertOpen(false)
  const [open1, setOpen1] = useState(false)
  const [editSelectedData, setEditCheckedData] = useState<ExtensionCounterModel | EmptyObject>({})
  const [selectedData, setSelectedData] = useState<Array<string>>([])

  const handleClose1 = () => setOpen1(false)

  const handleChangeLimit = (dataKey: number) => {
    setParams({
      ...params,
      limit: dataKey,
    })
  }

  const extensionCounterData: IExtensionCounterState = useSelector(
    (state: any) => state.extensionCounter
  )

  useEffect(() => {
    dispatch(extensionCounterRedux?.actions?.getExtensionCounter(params))
  }, [params])

  const {
    success,
    importSuccess,
    activateSuccess,
    deactivateSuccess,
    singleActivateSuccess,
    singleDeactivateSuccess,
    deleteSuccess,
    loading,
  } = extensionCounterData

  const handleToggleAction = (data: { [key: string]: string }) => {
    data?.status
      ? dispatch(extensionCounterRedux.actions.singleDeactivateExtensionCounter({ id: data.id }))
      : dispatch(extensionCounterRedux.actions.singleActivateExtensionCounter({ id: data.id }))
  }

  const ActionCell = ({ rowData, dataKey, ...props }: any) => {
    return (
      <Cell {...props} className='link-group'>
        <CheckPermissions type='Edit'>
          <Whisper placement='top' trigger='hover' speaker={<Tooltip>Edit</Tooltip>}>
            <IconButton
              appearance='subtle'
              onClick={() => {
                setEditCheckedData(rowData)
                setActionType('Edit')
                setOpen1(true)
              }}
              icon={<AiOutlineEdit />}
              data-cy='edit-button'
            />
          </Whisper>
        </CheckPermissions>
        <CheckPermissions type='Delete'>
          <Whisper placement='top' trigger='hover' speaker={<Tooltip>Delete</Tooltip>}>
            <IconButton
              appearance='subtle'
              onClick={() => {
                handleAlertOpen()
                let bannerId = [rowData.id]
                setSelectedData(bannerId)
              }}
              icon={<TrashIcon />}
              data-cy='delete-button'
            />
          </Whisper>
        </CheckPermissions>
        <Whisper placement='top' trigger='hover' speaker={<Tooltip>Status</Tooltip>}>
          <Toggle size='sm' checked={rowData.status} onClick={() => handleToggleAction(rowData)} data-cy='toggle-button' />
        </Whisper>
      </Cell>
    )
  }

  const columns = [
    {
      label: 'S.N.',
      dataKey: 'sn',
      width: 60,
      cell: <Cell dataKey='sn' />,
    },
    {
      label: 'Name',
      flexGrow: 1,
      dataKey: 'name',
      cell: <Cell dataKey='name' />,
      sortable: true,
    },
    {
      label: 'Address',
      dataKey: 'address',
      flexGrow: 1,
      cell: <Cell dataKey='address' />,
      sortable: true,
    },

    {
      label: 'Contact Person',
      dataKey: 'contact_person_en',
      flexGrow: 1,
      cell: <Cell dataKey='contact_person_en' />,
    },
    {
      label: 'Status',
      dataKey: 'status',
      width: 85,
      cell: (
        <Cell>
          {(rowData) =>
            rowData?.status ? (
              <Tag color='green' size='sm'>
                Active
              </Tag>
            ) : (
              <Tag color='red' size='sm'>
                Inactive
              </Tag>
            )
          }
        </Cell>
      ),
      sortable: false,
    },

    {
      label: 'Action',
      width: 150,
      align: 'center',
      cell: <ActionCell dataKey='id' />,
    },
  ]

  useEffect(() => {
    if (activateSuccess) {
      toast.success('Extension Counter activated successfully')
    }
    if (deactivateSuccess) {
      toast.success('Extension Counter deactivated successfully')
    }
    if (deleteSuccess) {
      toast.success('Extension Counter deleted successfully')
    }
    if (importSuccess) {
      toast.success('Extension Counter file imported successfully')
    }
    handleCheck([])
    dispatch(extensionCounterRedux?.actions?.getExtensionCounter(params))
  }, [activateSuccess, deactivateSuccess, deleteSuccess, success, importSuccess])
  useEffect(() => {
    if (singleActivateSuccess) {
      toast.success(' Extension Counter Activated successfully')
    }
    if (singleDeactivateSuccess) {
      toast.success(' Extension Counter deactivated successfully')
    }
    handleCheck([])
  }, [singleActivateSuccess, singleDeactivateSuccess])

  const handleCheck = (data: any) => {
    setSelectedData(data)
  }
  const activateSelectedData = () => {
    if (!isEmpty(selectedData)) {
      const formData = selectedData.map((d: string) => ({
        id: d,
      }))
      dispatch(extensionCounterRedux.actions.activateExtensionCounter(formData))
    } else {
      toast.error('No data Selected')
    }
  }

  const deactivateSelectedData = () => {
    if (!isEmpty(selectedData)) {
      const formData = selectedData.map((d: string) => ({
        id: d,
      }))
      dispatch(extensionCounterRedux.actions.deactivateExtensionCounter(formData))
    } else {
      toast.error('No data Selected')
    }
  }

  const handleRefresh = () => {
    dispatch(extensionCounterRedux.actions.getExtensionCounter(params))
  }

  //Get data from api to map in datatable
  const data = extensionCounterData?.data?.extensioncounter
    ? extensionCounterData?.data?.extensioncounter?.map((item: any, i: number) => ({
      sn: (params?.page - 1) * params?.limit + (i + 1),
      id: item?.id,
      name: item?.name,
      name_np: item?.name_np,
      province: item?.province?.id,
      district: item?.district?.id,
      plusCode: item?.plusCode,
      email: item?.email,
      address: item.address,
      address_np: item?.address_np,
      contact_no: item?.contact_no,
      latitude: item?.latitude,
      longitude: item?.longitude,
      contact_person_np: item?.contact_person_np,
      contact_person_en: item?.contact_person_en,
      status: item?.status,
    }))
    : []
  const handleAddModal = () => {
    setEditCheckedData({})
    setOpen1(true)
    setActionType('Add')
  }
  const handleDelete = () => {
    let extensioncounter = selectedData?.map((value) => ({ id: value }))
    dispatch(extensionCounterRedux?.actions.deleteExtensionCounter(extensioncounter))
    handleAlertClose()
  }

  const handleExportFile = () => {
    dispatch(extensionCounterRedux?.actions?.getExtensionCounterFile('csv'))
    dispatch(extensionCounterRedux?.actions?.getExtensionCounter())
  }

  function fileImport(event: any) {
    if (!isEmpty(event.target.files)) {
      let formData = new FormData()
      formData.append('file', event.target.files[0])
      dispatch(extensionCounterRedux.actions.importExtensionCounter(formData))
      event.target.value = null
    }
  }
  const handleTemplateExport = () => {
    dispatch(extensionCounterRedux.actions.exportTemplateFile())
  }

  const settings: ISettingTypeState = useSelector((state: any) => state.settingType)

  const showInterestTitle =
    settings?.data?.setting?.filter((d) => d.name === 'showInterestTitle')[0]?.value === '1'
      ? true
      : false

  return (
    <div>
      <div className='shadow p-3 bg-white rounded'>
        <DesignComponent
          moduleName='Extension Counter'
          params={params}
          setParams={setParams}
          handleAddModal={handleAddModal}
          handleAlertOpen={handleAlertOpen}
          handleRefresh={handleRefresh}
          exportShow={true}
          importShow={true}
          handleExport={handleExportFile}
          handleImport={fileImport}
          enableMultiple={activateSelectedData}
          disableMultiple={deactivateSelectedData}
          data={data}
          loading={loading}
          templateButtonName='Download Template for Extension Counter'
          handleTemplateExport={handleTemplateExport}
          templateLinkShow={showInterestTitle ? true : false}
        />

        <div className='datatable'>
          <RSuiteTable
            showCheckbox={true}
            columns={columns}
            data={data}
            checkedValues={selectedData}
            showLoading={loading}
            onChecked={handleCheck}
          />
          <Pagination
            prev
            next
            first
            last
            ellipsis
            boundaryLinks
            maxButtons={5}
            size='sm'
            layout={['total', '-', 'limit', '|', 'pager', 'skip']}
            total={extensionCounterData?.data?.meta?.total || 0}
            limitOptions={[10, 20, 50, 100]}
            limit={params?.limit}
            activePage={params?.page}
            onChangePage={(value) => setParams({ ...params, page: value })}
            onChangeLimit={handleChangeLimit}
          />
        </div>

        {open1 && (
          <AddExtensionCounter
            open={open1}
            handleClose={handleClose1}
            actionType={actionType}
            editSelectedData={editSelectedData}
          />
        )}

        {alertOpen &&
          (!isEmpty(selectedData) ? (
            <DeleteModal
              handleClick={() => handleDelete()}
              isOpen={alertOpen}
              handleClose={() => handleAlertClose()}
            />
          ) : (
            toast.error('No data selected') && setAlertOpen(false)
          ))}
      </div>
    </div>
  )
}
export default ExtensionCounter
