import {Form, Formik} from 'formik'
import {isEmpty} from 'lodash'
import {useEffect, useState} from 'react'
import {Tab, Tabs} from 'react-bootstrap-v5'
import {useDispatch, useSelector} from 'react-redux'
import {toast} from 'react-toastify'
import Modal from 'rsuite/Modal'
import * as cms from 'src/app/modules/cms'
import * as bannerRedux from 'src/app/modules/cms/components/banner/redux'
import * as categories from 'src/app/modules/cms/components/categories'
import * as faqCategoryRedux from 'src/app/modules/cms/components/faqCategory/redux'
import * as categoryTypeRedux from 'src/app/modules/common'
import {StateParamsModel} from 'src/app/modules/common/Model'
import checkBackendSetting from 'src/app/modules/common/checkBackendSetting'
import checkMultiLanguage from 'src/app/modules/common/checkMultiLanguage'
import * as downloadRedux from 'src/app/modules/formsAndDownload/components/formsDownload/index'
import * as productRedux from 'src/app/modules/products'
import {ProductManagerModel} from 'src/app/modules/products/components/productManager/Model/ProductManagerModel'
import {ISettingTypeState} from 'src/app/modules/siteSettings/components/settings'
import * as Yup from 'yup'
import {BannerModel} from '../../banner/Model'
import {FaqCategoryModel} from '../../faqCategory/Model'
import {CategoryModel, ContentOptionModal} from '../Model'
import {IContentState} from '../redux/reducer'
import {AdditionalSection} from './TabComponents/AdditionalSection'
import {ApplyNowSection} from './TabComponents/ApplyNowSection'
import CollapsibleSection from './TabComponents/CollapsibleSection'
import {DownloadComponent} from './TabComponents/DownloadComponent'
import {FaqComponent} from './TabComponents/FaqComponent'
import {GeneralComponent} from './TabComponents/GeneralComponent'
import {HelpSection} from './TabComponents/HelpSection'
import {IconDataSection} from './TabComponents/IconDataSection'
import {ImageDataSection} from './TabComponents/ImageDataSection'
import {ProductComponent} from './TabComponents/ProductComponent'
import {SliderComponent} from './TabComponents/SliderComponent'

type Props = {
  open: boolean
  params?: StateParamsModel
  handleClose: () => void
  actionType: string
  editSelectedData?: any
}

const FORM_VALIDATION = {
  content_name: Yup.string().required('Content name is required'),
  categoryId: Yup.string().required('Category option is required'),
  // description: Yup.string().required('Description is required'),
  show_lead_form_id: Yup.string().required('Show lead option is required'),
  enable_page_header_id: Yup.string().required('Enable page header option is required'),
  show_banner_id: Yup.string().required('Show banner option is required'),
  show_review_id: Yup.string().required('Review option is required'),
  faqOptionId: Yup.string().required('Faq option is required'),
  // download_option_id: Yup.string().required('Download option is required'),
  show_collapsible_id: Yup.string().required('Show collapsible option is required'),
  productSectionId: Yup.string().required('Show product option is required'),
  helpSectionId: Yup.string().required('Show help option is required'),
  applySectionId: Yup.string().required('Show aply now option is required'),
}

const AddMenuModal = ({open, params, handleClose, actionType, editSelectedData}: Props) => {
  const dispatch = useDispatch()
  const [contentCategory, setContentCategory] = useState('')
  const [validationState, setValidationState] = useState(FORM_VALIDATION)
  const {
    contentBreadcrumbOption,
    sliderSectionOptions,
    landingPageOptions,
    applyNowSection,
    contentBannerOption,
    collapsibleSection,
    faqSection,
    downloadOption,
    helpSection,
    contentLeadFormOption,
    pageHeaderOption,
    productSection,
    reviewAndRatingOption,
    loading,
    success,
  } = useSelector((state: IContentState | any) => state.content)

  const settingTypeData: ISettingTypeState = useSelector((state: any) => state.settingType)

  const multiLanguage = checkMultiLanguage()

  const {
    data: {faqCategory},
  } = useSelector((state: any) => state.faqCategory)

  const {data} = useSelector((state: any) => state.formsAndDownload)

  const {
    allProductManager: {productManager},
  } = useSelector((state: any) => state.productManager)

  const {
    data: {categoryType},
  } = useSelector((state: any) => state.categoryType)

  const {menuList} = useSelector((state: any) => state.menuManager)

  const {
    data: {banner},
  } = useSelector((state: any) => state.banner)

  const {
    data: {category},
  } = useSelector((state: any) => state.categories)

  useEffect(() => {
    dispatch(categoryTypeRedux.action.getCategoryType())
  }, [])

  useEffect(() => {
    const contentCatType = categoryType?.filter(
      (item: {[key: string]: string}) => item.system_name === 'content'
    )
    setContentCategory(contentCatType[0]?.id)
  }, [categoryType])

  useEffect(() => {
    if (!isEmpty(contentCategory)) {
      dispatch(categories.actions.getSpecificCmsCategories(contentCategory))
    }
  }, [contentCategory])

  useEffect(() => {
    dispatch(cms.content.actions.getSliderSection())
    dispatch(cms.content.actions.getLandingPage())
    dispatch(cms.content.actions.getApplyNowSection())
    dispatch(cms.content.actions.getConstantBanner())
    dispatch(cms.content.actions.getCollapsibleSection())
    dispatch(cms.content.actions.getFaqOption())
    dispatch(cms.content.actions.getDownloadOption())
    dispatch(cms.content.actions.getHelpSection())
    dispatch(cms.content.actions.getLeadForm())
    dispatch(cms.content.actions.getPageHeader())
    dispatch(cms.content.actions.getProductOption())
    dispatch(cms.content.actions.getReviewAndRating())
    dispatch(bannerRedux.actions.getAllBanner())
    dispatch(cms.menu.actions.getAllMenu())
    dispatch(faqCategoryRedux.actions.getAllFaqCategory({status: true}))
    dispatch(productRedux.productManager.actions.getAllProductManager())
    dispatch(downloadRedux.actions.getFormsDownload({limit: 100}))
    dispatch(cms.content.actions.getContentBreadCrumbOptions())
  }, [])

  //Create options from API for FormSelect

  const menuOptions = menuList?.menu?.map((items: any) => ({
    label: items.tableViewName,
    value: items.id,
    children: items?.children?.map((child: any) => ({
      label: child.tableViewName,
      value: child.id,
    })),
  }))

  const landingPageOption = landingPageOptions?.map((items: ContentOptionModal) => ({
    label: items.displayName,
    systemName: items.systemName,

    value: items.id,
  }))

  const sliderSectionOption = sliderSectionOptions?.map((items: ContentOptionModal) => ({
    label: items.displayName,
    systemName: items.systemName,

    value: items.id,
  }))
  const applyNowSectionOptions = applyNowSection?.map((items: ContentOptionModal) => ({
    label: items.displayName,
    systemName: items.systemName,

    value: items.id,
  }))
  const categoryOptions = category?.map((items: CategoryModel) => ({
    label: items.name,
    value: items.id,
  }))

  const categoryTypeOptions = categoryType?.map((items: any) => ({
    label: items.display_name,
    value: items.id,
  }))

  const productDataOptions = productManager?.map((items: ProductManagerModel) => ({
    label: items.title,
    value: items.id,
  }))

  const bannerOptions = contentBannerOption?.map((items: ContentOptionModal) => ({
    label: items.displayName,
    systemName: items.systemName,
    value: items.id,
  }))
  const bannerData = banner?.map((items: BannerModel) => ({
    label: multiLanguage ? items.name : items.title,
    value: items.id,
  }))

  const faqCategoryOptions = faqCategory?.map((items: FaqCategoryModel) => ({
    label: items.name,
    value: items.id,
  }))

  const downloadDataOptions = data?.download?.map((items: any) => ({
    label: items.title,
    value: items.id,
  }))

  const collapsibleSectionOptions = collapsibleSection?.map((items: ContentOptionModal) => ({
    label: items.displayName,
    systemName: items.systemName,
    value: items.id,
  }))
  const faqOptionOptions = faqSection?.map((items: ContentOptionModal) => ({
    label: items.displayName,
    systemName: items.systemName,
    value: items.id,
  }))
  const downloadOptionOptions = downloadOption?.map((items: ContentOptionModal) => ({
    label: items.displayName,
    systemName: items.systemName,
    value: items.id,
  }))
  const helpSectionOptions = helpSection?.map((items: ContentOptionModal) => ({
    label: items.displayName,
    systemName: items.systemName,
    value: items.id,
  }))
  const leadFormOptions = contentLeadFormOption?.map((items: ContentOptionModal) => ({
    label: items.displayName,
    systemName: items.systemName,

    value: items.id,
  }))
  const pageHeaderOptions = pageHeaderOption?.map((items: ContentOptionModal) => ({
    label: items.displayName,
    systemName: items.systemName,

    value: items.id,
  }))
  const productOption = productSection?.map((items: ContentOptionModal) => ({
    label: items.displayName,
    value: items.id,
    systemName: items.systemName,
  }))
  const reviewAndRatingOptions = reviewAndRatingOption?.map((items: ContentOptionModal) => ({
    label: items.displayName,
    value: items.id,
    systemName: items.systemName,
  }))
  const breadcrumbOptions = contentBreadcrumbOption?.map((items: ContentOptionModal) => ({
    label: items.displayName,
    systemName: items.systemName,
    value: items.id,
  }))

  useEffect(() => {
    if (success) {
      isEmpty(editSelectedData)
        ? toast.success('Content added successfully')
        : toast.success('Content edited successfully')
      dispatch(cms.content.actions.resetContent())
      handleClose()
    }
  }, [success])

  const settings: ISettingTypeState = useSelector((state: any) => state.settingType)

  const showContentPageSlider =
    settings?.data?.setting?.filter((d) => d.name === 'showContentPageSlider')[0]?.value === '1'
      ? true
      : false
  const showContentPageIconAndImageTab =
    settings?.data?.setting?.filter((d) => d.name === 'showContentPageIconAndImageTab')[0]
      ?.value === '1'
      ? true
      : false
  const showProductAndInterest =
    settings?.data?.setting?.filter((d) => d.name === 'showPopularityAndInterest')[0]?.value === '1'
      ? true
      : false

  const showInterestTitle =
    settingTypeData?.data?.setting?.filter((d) => d.name === 'showInterestTitle')[0]?.value === '1'
      ? true
      : false

  const settingTypeDwnload: any = settingTypeData?.data?.setting
    ?.filter((item: any) => item.name === 'nicDownloadPayload')
    .map((item: any) => item)

  const hideCollapsibleSection = checkBackendSetting('hideCollapsibleSection')
  const hideIconData = checkBackendSetting('hideIconData')
  const hideImageDataSection = checkBackendSetting('hideImageDataSection')
  const hideSliderSection = checkBackendSetting('hideSliderSection')
  const hideApplyNowSection = checkBackendSetting('hideApplyNowSection')
  const hideFAQSection = checkBackendSetting('hideFAQSection')
  const hideProductSection = checkBackendSetting('hideProductSection')
  const hideHelpSection = checkBackendSetting('hideHelpSection')
  const hideAdditionalSection = checkBackendSetting('hideAdditionalSection')

  return (
    <div className='modal-container'>
      <Modal
        open={open}
        onClose={handleClose}
        className='w-75'
        style={{minHeight: '90vh'}}
        backdrop='static'
        keyboard={false}
        enforceFocus={false}
        data-cy='modal'
      >
        <Modal.Header>
          <Modal.Title data-cy='modal-title'>{actionType} Content</Modal.Title>
        </Modal.Header>
        <Modal.Body style={{minHeight: '80vh'}}>
          <div>
            <Formik
              validationSchema={Yup.object().shape(validationState)}
              initialValues={{
                slug: '',
                collapsibleData: [
                  {title: '', title_np: '', description: null, description_np: null},
                ],
                sliderData: [
                  {
                    title: '',
                    title_np: '',
                    imageData: [{name: '', name_np: '', image: '', link: ''}],
                  },
                ],
                helpSection: {
                  text: '',
                  text_np: '',
                  sub_text: '',
                  sub_text_np: '',
                  image: '',
                  image_np: '',
                  first_button_text: '',
                  first_button_text_np: '',
                  first_button_link: '',
                  second_button_text: '',
                  second_button_text_np: '',
                  second_button_link: '',
                },
                applySection: {
                  text: '',
                  text_np: '',
                  first_button_text: '',
                  first_button_text_np: '',
                  first_button_link: '',
                  second_button_text: '',
                  second_button_text_np: '',
                  second_button_link: '',
                },
                menuId: '',
                productData: {
                  productId: '',
                  text: '',
                  text_np: '',
                  sub_text: '',
                  sub_text_np: '',
                  button_text: '',
                  button_text_np: '',
                  button_link: '',
                },
                landing_page_id: '',
                rating: '',
                content_name: '',
                categoryId: '',
                description: '',
                description_np: '',
                show_lead_form_id: '',
                enable_page_header_id: '',
                additionalData: [
                  {text: '', textNp: '', description: '', descriptionNp: '', order: ''},
                ],
                iconData: [{image: '', title: '', order: ''}],
                imageData: [{image: '', title: '', order: ''}],
                show_banner_id: '',
                show_review_id: '',
                faqOptionId: '',
                download_option_id: '',
                downloadSection: [],
                show_collapsible_id: '',
                collapsible_title: '',
                productSectionId: '',
                helpSectionId: '',
                applySectionId: '',
                show_slider_id: '',
              }}
              onSubmit={(values) => {
                const formData = {
                  ...values,
                  sliderData: values?.sliderData?.map((main: any) => ({
                    title: main.title,
                    title_np: main.title_np,
                    imageData: main.imageData.map((icons: any) => ({
                      name: icons.name,
                      name_np: icons.name_np || '',
                      image: icons.image,
                      link: icons.link,
                    })),
                  })),
                  helpSection: [
                    {
                      text: values.helpSection.text,
                      text_np: values.helpSection.text_np,
                      sub_text: values.helpSection.sub_text,
                      sub_text_np: values.helpSection.sub_text_np,
                      image: values.helpSection.image,
                      image_np: values.helpSection.image_np,
                      first_button_text: values.helpSection.first_button_text,
                      first_button_text_np: values.helpSection.first_button_text_np,
                      first_button_link: values.helpSection.first_button_link,
                      second_button_text: values.helpSection.second_button_text,
                      second_button_text_np: values.helpSection.second_button_text_np,
                      second_button_link: values.helpSection.second_button_link,
                    },
                  ],
                  applySection: [
                    {
                      text: values.applySection.text,
                      text_np: values.applySection.text_np,
                      first_button_text: values.applySection.first_button_text,
                      first_button_text_np: values.applySection.first_button_text_np,
                      first_button_link: values.applySection.first_button_link,
                      second_button_text: values.applySection.second_button_text,
                      second_button_text_np: values.applySection.second_button_text_np,
                      second_button_link: values.applySection.second_button_link,
                    },
                  ],
                  productData: [
                    {
                      productId: values.productData.productId,
                      text: values.productData.text,
                      text_np: values.productData.text_np,
                      sub_text: values.productData.sub_text,
                      sub_text_np: values.productData.sub_text_np,
                      button_text: values.productData.button_text,
                      button_text_np: values.productData.button_text_np,
                      button_link: values.productData.button_link,
                    },
                  ],
                  additionalData: values?.additionalData?.map((item: {[key: string]: string}) => ({
                    ...item,
                    id: !isEmpty(editSelectedData) ? item.id : null,
                  })),
                  collapsibleData: values?.collapsibleData?.map(
                    (item: {[key: string]: string | any}) => ({
                      ...item,
                      id: !isEmpty(editSelectedData) ? item.id : null,
                    })
                  ),
                  slug: !isEmpty(editSelectedData)
                    ? editSelectedData?.slug
                    : values?.content_name.replace(/\s/g, '-').toLowerCase(),
                  downloadSection:
                    !isEmpty(values?.downloadSection) && settingTypeDwnload[0]?.value !== '1'
                      ? values?.downloadSection?.map((item: {id: string}) => ({
                          id: item,
                        }))
                      : null,
                }

                if (!isEmpty(editSelectedData)) {
                  dispatch(cms.content.actions.updateContent(formData, editSelectedData?.id))
                } else {
                  dispatch(cms.content.actions.addContent(formData))
                }
              }}
            >
              {({isSubmitting, touched, handleChange, errors, values, setFieldValue}) => {
                useEffect(() => {
                  if (!isEmpty(editSelectedData)) {
                    const fields = [
                      'id',
                      'content_name',
                      'content_name_np',
                      'description',
                      'description_np',
                      'slug',
                      'categoryId',
                      'show_lead_form_id',
                      'enable_page_header_id',
                      'show_banner_id',
                      'show_review_id',
                      'show_collapsible_id',
                      'collapsible_title',
                      'collapsible_title_np',
                      'landing_page_id',
                      'iconData',
                      'imageData',
                      'collapsibleData',
                      'rating',
                      'menuId',
                      'bannerId',
                      'additionalData',
                      'faqOptionId',
                      'faqId',
                      'download_option_id',
                      'downloadSection',
                      'helpSectionId',
                      'helpSection',
                      'applySectionId',
                      'applySection',
                      'productSectionId',
                      'show_slider_id',
                      'show_breadcrumb_id',
                      'sliderData',
                      'productData',
                      'modelType',
                    ]
                    fields.forEach((field) => setFieldValue(field, editSelectedData[field], false))
                    setFieldValue(
                      'status',
                      editSelectedData?.status === true ? 'Active' : 'Inactive',
                      false
                    )
                    setFieldValue(
                      'productData.productId',
                      editSelectedData?.productData?.product?.id,
                      false
                    )
                  }
                }, [])

                useEffect(() => {
                  //Default Value No

                  if (actionType === 'Add') {
                    setFieldValue(
                      'landing_page_id',
                      landingPageOption
                        ?.filter((items: any) => items.systemName === 'no')
                        .map((item: any) => item.value)
                        .toString(),

                      false
                    )
                    setFieldValue(
                      'show_breadcrumb_id',
                      breadcrumbOptions
                        ?.filter((items: any) => items.systemName === 'no')
                        .map((item: any) => item.value)
                        .toString(),
                      false
                    )
                    setFieldValue(
                      'show_lead_form_id',
                      leadFormOptions
                        ?.filter((items: any) => items.systemName === 'no')
                        .map((item: any) => item.value)
                        .toString(),

                      false
                    )
                    setFieldValue(
                      'enable_page_header_id',
                      pageHeaderOptions
                        ?.filter((items: any) => items.systemName === 'no')
                        .map((item: any) => item.value)
                        .toString(),
                      false
                    )
                    setFieldValue(
                      'show_review_id',
                      reviewAndRatingOptions
                        ?.filter((items: any) => items.systemName === 'no')
                        .map((item: any) => item.value)
                        .toString(),
                      false
                    )
                    setFieldValue(
                      'show_banner_id',
                      bannerOptions
                        ?.filter((items: any) => items.systemName === 'no')
                        .map((item: any) => item.value)
                        .toString(),
                      false
                    )
                    setFieldValue(
                      'faqOptionId',
                      faqOptionOptions
                        ?.filter((items: any) => items.systemName === 'no')
                        .map((item: any) => item.value)
                        .toString(),

                      false
                    )
                    setFieldValue(
                      'download_option_id',
                      downloadOptionOptions
                        ?.filter((items: any) => items.systemName === 'no')
                        .map((item: any) => item.value)
                        .toString(),

                      false
                    )
                    setFieldValue(
                      'show_collapsible_id',
                      collapsibleSectionOptions
                        ?.filter((items: any) => items.systemName === 'no')
                        .map((item: any) => item.value)
                        .toString(),
                      false
                    )
                    setFieldValue(
                      'productSectionId',
                      productOption
                        ?.filter((items: any) => items.systemName === 'no')
                        .map((item: any) => item.value)
                        .toString(),
                      false
                    )
                    setFieldValue(
                      'applySectionId',
                      applyNowSectionOptions
                        ?.filter((items: any) => items.systemName === 'no')
                        .map((item: any) => item.value)
                        .toString(),
                      false
                    )
                    setFieldValue(
                      'show_slider_id',
                      sliderSectionOption
                        ?.filter((items: any) => items.systemName === 'no')
                        .map((item: any) => item.value)
                        .toString(),
                      false
                    )
                    setFieldValue(
                      'helpSectionId',
                      helpSectionOptions
                        ?.filter((items: any) => items.systemName === 'no')
                        .map((item: any) => item.value)
                        .toString(),
                      false
                    )
                  }
                }, [
                  breadcrumbOptions.length,
                  helpSectionOptions.length,
                  landingPageOption.length,
                  sliderSectionOption.length,
                  applyNowSectionOptions.length,
                  productOption.length,
                  collapsibleSectionOptions.length,
                  faqOptionOptions.length,
                  downloadOptionOptions.length,
                  bannerOptions.length,
                  reviewAndRatingOptions.length,
                  pageHeaderOptions.length,
                  leadFormOptions.length,
                ])

                const multiLanguage = checkMultiLanguage()

                return (
                  <Form>
                    <div className='card-body border-top'>
                      <div className=''>
                        <div className='row'>
                          <Tabs
                            defaultActiveKey='general'
                            id='uncontrolled-tab-example'
                            className='mb-5'
                          >
                            <Tab eventKey='general' title='General'>
                              <div className='card-body bg-white'>
                                <GeneralComponent
                                  handleChange={handleChange}
                                  touched={touched}
                                  errors={errors}
                                  values={values}
                                  breadcrumbOptions={breadcrumbOptions}
                                  bannerOptions={bannerOptions}
                                  bannerData={bannerData}
                                  menuOptions={menuOptions}
                                  leadFormOptions={leadFormOptions}
                                  pageHeaderOptions={pageHeaderOptions}
                                  landingPageOption={landingPageOption}
                                  categoryOptions={categoryOptions}
                                  reviewAndRatingOptions={reviewAndRatingOptions}
                                  editSelectedData={editSelectedData}
                                  validationState={validationState}
                                  setValidationState={setValidationState}
                                  setFieldValue={setFieldValue}
                                />
                              </div>
                            </Tab>

                            {/* hide this tab for nimb */}

                            {!hideFAQSection && (
                              <Tab eventKey='faqSection' title='FAQ Section'>
                                <div className='card-body bg-white'>
                                  <FaqComponent
                                    handleChange={handleChange}
                                    touched={touched}
                                    errors={errors}
                                    values={values}
                                    faqOptionOptions={faqOptionOptions}
                                    faqCategoryOptions={faqCategoryOptions}
                                    validationState={validationState}
                                    setValidationState={setValidationState}
                                    setFieldValue={setFieldValue}
                                  />
                                </div>
                              </Tab>
                            )}
                            {multiLanguage && (
                              <Tab eventKey='downloadSection' title='Download Section'>
                                <div className='card-body bg-white'>
                                  <DownloadComponent
                                    handleChange={handleChange}
                                    touched={touched}
                                    errors={errors}
                                    values={values}
                                    downloadOptionOptions={downloadOptionOptions}
                                    downloadDataOption={downloadDataOptions}
                                    validationState={validationState}
                                    setValidationState={setValidationState}
                                    setFieldValue={setFieldValue}
                                  />
                                </div>
                              </Tab>
                            )}

                            {!hideProductSection && !multiLanguage && (
                              <Tab eventKey='productSection' title='Product Section'>
                                <div className='card-body bg-white'>
                                  <ProductComponent
                                    handleChange={handleChange}
                                    touched={touched}
                                    errors={errors}
                                    values={values}
                                    productOptionOptions={productOption}
                                    productDataOptions={productDataOptions}
                                    validationState={validationState}
                                    setValidationState={setValidationState}
                                    setFieldValue={setFieldValue}
                                  />
                                </div>
                              </Tab>
                            )}

                            {!hideHelpSection && !multiLanguage && (
                              <Tab eventKey='helpSection' title='Help Section'>
                                <div className='card-body bg-white'>
                                  <HelpSection
                                    handleChange={handleChange}
                                    touched={touched}
                                    errors={errors}
                                    values={values}
                                    setFieldValue={setFieldValue}
                                    helpSectionOptions={helpSectionOptions}
                                    validationState={validationState}
                                    setValidationState={setValidationState}
                                  />
                                </div>
                              </Tab>
                            )}

                            {!hideApplyNowSection && (
                              <Tab eventKey='applyNowSection' title='Apply Now Section'>
                                <div className='card-body bg-white'>
                                  <ApplyNowSection
                                    handleChange={handleChange}
                                    touched={touched}
                                    errors={errors}
                                    values={values}
                                    applyNowSectionOptions={applyNowSectionOptions}
                                    validationState={validationState}
                                    setValidationState={setValidationState}
                                    setFieldValue={setFieldValue}
                                  />
                                </div>
                              </Tab>
                            )}

                            {!hideAdditionalSection && (
                              <Tab eventKey='additionalSection' title='Additional Section'>
                                <div className='card-body bg-white'>
                                  <AdditionalSection
                                    handleChange={handleChange}
                                    touched={touched}
                                    errors={errors}
                                    values={values}
                                    editSelectedData={editSelectedData}
                                  />
                                </div>
                              </Tab>
                            )}

                            {!hideCollapsibleSection && (
                              <Tab eventKey='collapsibleSection' title='Collapsible Section'>
                                <div className='card-body bg-white'>
                                  <CollapsibleSection
                                    handleChange={handleChange}
                                    touched={touched}
                                    errors={errors}
                                    values={values}
                                    editSelectedData={editSelectedData}
                                    collapsibleSectionOptions={collapsibleSectionOptions}
                                    validationState={validationState}
                                    setValidationState={setValidationState}
                                    setFieldValue={setFieldValue}
                                  />
                                </div>
                              </Tab>
                            )}
                            {!hideIconData && (
                              <Tab eventKey='iconData' title='Icon Data'>
                                <div className='card-body bg-white'>
                                  <IconDataSection
                                    handleChange={handleChange}
                                    touched={touched}
                                    errors={errors}
                                    values={values}
                                    editSelectedData={editSelectedData}
                                    setFieldValue={setFieldValue}
                                  />
                                </div>
                              </Tab>
                            )}
                            {!hideImageDataSection && (
                              <Tab eventKey='imageData' title='Image Data'>
                                <div className='card-body bg-white'>
                                  <ImageDataSection
                                    handleChange={handleChange}
                                    touched={touched}
                                    errors={errors}
                                    values={values}
                                    editSelectedData={editSelectedData}
                                    setFieldValue={setFieldValue}
                                  />
                                </div>
                              </Tab>
                            )}
                            {!hideSliderSection && (
                              <Tab eventKey='sliderSection' title='Slider Section'>
                                <div className='card-body bg-white'>
                                  <SliderComponent
                                    handleChange={handleChange}
                                    touched={touched}
                                    errors={errors}
                                    values={values}
                                    sliderSectionOptions={sliderSectionOption}
                                    validationState={validationState}
                                    setValidationState={setValidationState}
                                    setFieldValue={setFieldValue}
                                  />
                                </div>
                              </Tab>
                            )}
                          </Tabs>
                        </div>

                        <div className='d-flex justify-content-end px-9 '>
                          <button
                            type='submit'
                            disabled={loading}
                            className='btn btn-primary btn-sm ms-3'
                            data-cy='modal-submit'
                          >
                            Save
                          </button>
                          <button
                            type='button'
                            onClick={handleClose}
                            className='btn btn-secondary btn-sm ms-3'
                            data-cy='modal-cancel'
                          >
                            Cancel
                          </button>
                        </div>
                      </div>
                    </div>
                  </Form>
                )
              }}
            </Formik>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  )
}
export default AddMenuModal
