import {Col, Row} from 'react-bootstrap-v5'
import {Modal} from 'rsuite'
import {imageBaseUrl} from 'src/cms/helpers/constants'

type Props = {
  data: {
    id: String
    name: String
    uniqueCode: String
    dobAd: String
    dobBs: String
    age: String
    gender: String
    grandfatherName: String
    fatherName: String
    motherName: String
    placeOfBirth: String
    bloodGroup: String
    citizenshipNumber: String
    districtId: String
    citizenshipIssueDateAd: String
    citizenshipIssueDateBs: String
    maritalStatus: String
    email: String
    mobileNumber: String
    telephone: String
    sameAsPermanent: true
    workedHere: true
    internshipStatusId: String
    status: true
    internshipPermanentAddress: {
      id: String
      internshipId: String
      provinceId: String
      zoneId: String
      districtId: String
      municipalityId: String
      wardNumber: String
      tole: String
    }
    internshipTemporaryAddress: {
      id: String
      internshipId: String
      provinceId: String
      zoneId: String
      districtId: String
      municipalityId: String
      wardNumber: String
      tole: String
    }
    internshipEducation: {
      internshipEducation: [
        {
          id: String
          internshipId: String
          nameAddressInstituition: String
          university: String
          qualificationId: String
          areaSpecialization: String
          passingYear: String
          percentage: String
          cgpa: String
          marksheet: String
        }
      ]
    }
    internshipTraining: {
      internshipTraining: [
        {
          id: String
          internshipId: String
          name: String
          instituteName: String
          duration: String
          completionYear: String
        }
      ]
    }
    internshipProfessionalReference: {
      internshipProfessionalReference: [
        {
          id: String
          internshipId: String
          name: String
          address: String
          email: String
          phoneNumber: String
          organizationName: String
        }
      ]
    }
    internshipDocument: {
      id: String
      internshipId: String
      latestPhoto: String
      resume: String
      citizenship: String
      application: String
      recommendationLetter: String
      frontCitizenship: String
      backCitizenship: String
    }
    internshipCurrentExperience: {
      internshipCurrentExperience: [
        {
          id: String
          internshipId: String
          organizationName: String
          designation: String
          functionalAreaId: String
          fromDate: String
          toDate: String
          salaryPerMonth: String
          jobResponsibilities: String
          lastPromotionDate: String
          document: String
          expectedSalary: String
        }
      ]
    }
    internshipRecentExperience: {
      internshipRecentExperience: [
        {
          id: String
          internshipId: String
          organizationName: String
          designation: String
          functionalAreaId: String
          fromDate: String
          toDate: String
          salaryPerMonth: String
          jobResponsibilities: String
          totalMonth: String
          reasonForLeaving: String
        }
      ]
    }
    internshipWorkedHereDetail: {
      id: String
      internshipId: String
      branchId: String
      departmentId: String
      position: String
      fromDate: String
      toDate: String
      status: String
    }
  }
  handleClose: () => void
  provinceList: Array<any>
  districtList: Array<any>
  zoneList: Array<any>
  municipalityList: Array<any>
  functionalAreaList: Array<any>
  branchList: Array<any>
  departmentList: Array<any>
}

const ViewCandidateDetails = ({
  data,
  handleClose,
  branchList,
  departmentList,
  provinceList,
  districtList,
  zoneList,
  municipalityList,
  functionalAreaList,
}: Props) => {
  return (
    <div className='modal-container'>
      <Modal
        open={true}
        onClose={handleClose}
        className='w-75'
        backdrop='static'
        keyboard={false}
        enforceFocus={false}
        data-cy='modal'
      >
        <Modal.Header>
          <Modal.Title data-cy='modal-title'>Candidate Details </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            <h5>Personal Details</h5>
            <Row>
              <Col md={3}>Name : {data?.name}</Col>
              <Col md={3}>Unique Code : {data?.uniqueCode}</Col>
              <Col md={3}>Date Of Birth (B.S): {data?.dobBs}</Col>
              <Col md={3}>Date Of Birth (A.D): {data?.dobAd}</Col>
            </Row>
            <Row className='mt-5'>
              <Col md={3}>Age : {data?.age}</Col>
              <Col md={3}>Gender : {data?.gender}</Col>
              <Col md={3}>Father's Name: {data?.fatherName}</Col>
              <Col md={3}>Grandfather's Name: {data?.grandfatherName}</Col>
            </Row>
            <Row className='mt-5'>
              <Col md={3}>Mother's Name : {data?.motherName}</Col>
              <Col md={3}>Marital Status : {data?.maritalStatus}</Col>
            </Row>
            <Row className='mt-5'>
              <Col md={3}>Place Of Birth : {data?.placeOfBirth}</Col>
              <Col md={3}>Blood Group : {data?.bloodGroup}</Col>
              <Col md={3}>Citizenship Number: {data?.citizenshipNumber}</Col>
              <Col md={3}>Citizenship Issued Date (B.S): {data?.citizenshipIssueDateBs}</Col>
            </Row>
            <Row className='mt-5'>
              <Col md={3}>Citizenship Issued Date (A.D): {data?.citizenshipIssueDateAd}</Col>
              <Col md={3}>Email : {data?.email}</Col>
              <Col md={3}>Mobile Number: {data?.mobileNumber}</Col>
              <Col md={3}>Home Telephone: {data?.telephone || 'N/A'}</Col>
            </Row>
            <Row className='mt-5'>
              <Col md={3}>
                Permanent Province:{' '}
                {provinceList.filter(
                  (d: any) => d.id === data?.internshipPermanentAddress?.provinceId
                )[0]?.title || 'N/A'}
              </Col>
              <Col md={3}>
                Permanent Zone:{' '}
                {zoneList.filter((d: any) => d.id === data?.internshipPermanentAddress?.zoneId)[0]
                  ?.title || 'N/A'}
              </Col>
              <Col md={3}>
                Permanent District:{' '}
                {districtList.filter(
                  (d: any) => d.id === data?.internshipPermanentAddress?.districtId
                )[0]?.title || 'N/A'}
              </Col>
              <Col md={3}>
                Permanent Municipality/VDC:{' '}
                {municipalityList.filter(
                  (d: any) => d.id === data?.internshipPermanentAddress?.municipalityId
                )[0]?.title || 'N/A'}
              </Col>
            </Row>
            <Row className='mt-5'>
              <Col md={3}>
                Permanent Ward Number: {data?.internshipPermanentAddress?.wardNumber}
              </Col>
              <Col md={3}>Permanent Tole: {data?.internshipPermanentAddress?.tole}</Col>
              <Col md={3}>
                Present Province:{' '}
                {provinceList.filter(
                  (d: any) => d.id === data?.internshipTemporaryAddress?.provinceId
                )[0]?.title || 'N/A'}
              </Col>
              <Col md={3}>
                Present Zone:{' '}
                {zoneList.filter((d: any) => d.id === data?.internshipTemporaryAddress?.zoneId)[0]
                  ?.title || 'N/A'}{' '}
              </Col>
            </Row>
            <Row className='mt-5'>
              <Col md={3}>
                Present District:{' '}
                {districtList.filter(
                  (d: any) => d.id === data?.internshipTemporaryAddress?.districtId
                )[0]?.title || 'N/A'}
              </Col>
              <Col md={3}>
                Present Municipality/VDC:{' '}
                {municipalityList.filter(
                  (d: any) => d.id === data?.internshipTemporaryAddress?.municipalityId
                )[0]?.title || 'N/A'}
              </Col>
              <Col md={3}>Present Tole: {data?.internshipTemporaryAddress?.tole}</Col>
            </Row>

            <h5 className='mt-5'>Education Details</h5>
            {data?.internshipEducation?.internshipEducation?.map(
              (education: any, index: number) => {
                return (
                  <div key={index}>
                    <h5 className='mt-2'>Education {index + 1}</h5>
                    <Row className='mt-5'>
                      <Col md={3}>University/College: {education?.university}</Col>
                      <Col md={3}>Institution Address : {education?.nameAddressInstituition}</Col>
                      <Col md={3}>Area Of Specialization: {education?.areaSpecialization}</Col>
                      <Col md={3}>Passing Year: {education?.passingYear}</Col>
                    </Row>
                    <Row className='mt-5'>
                      <Col md={3}>CGPA: {education?.cgpa}</Col>
                      <Col md={3}>Percentage : {education?.percentage}</Col>
                      {/* <Col md={3}>
                        Marksheet:{' '}
                        <a target='_blank' href={imageBaseUrl + '/' + education.marksheet}>
                          View
                        </a>
                      </Col> */}
                    </Row>
                  </div>
                )
              }
            )}

            {data?.internshipCurrentExperience?.internshipCurrentExperience?.length > 0 && (
              <div>
                <h5 className='mt-5'>Current Experience Details</h5>

                {data?.internshipCurrentExperience?.internshipCurrentExperience?.map(
                  (currentExperience: any, index: number) => {
                    return (
                      <div key={index}>
                        <h5 className='mt-2'>Current Experience {index + 1}</h5>
                        <Row className='mt-5'>
                          <Col md={3}>Organization Name: {currentExperience?.organizationName}</Col>
                          <Col md={3}>Designation: {currentExperience?.designation}</Col>
                          <Col md={3}>
                            Functional Area:{' '}
                            {functionalAreaList.filter(
                              (d: any) => d.id === currentExperience.functionalAreaId
                            )[0]?.name || 'N/A'}
                          </Col>
                          <Col md={3}>Salary Per Month: {currentExperience?.salaryPerMonth}</Col>
                        </Row>
                        <Row className='mt-5'>
                          <Col md={3}>From Date: {currentExperience?.fromDate}</Col>
                          <Col md={3}>To Date: {currentExperience?.toDate}</Col>
                          <Col md={3}>
                            Job Responsibilities: {currentExperience?.jobResponsibilities}
                          </Col>
                          <Col md={3}>
                            Last Promotion Date: {currentExperience?.lastPromotionDate}
                          </Col>
                        </Row>
                        <Row className='mt-5'>
                          <Col md={3}>
                            Document:{' '}
                            <a
                              target='_blank'
                              href={imageBaseUrl + '/' + currentExperience?.document}
                            >
                              View
                            </a>
                          </Col>
                          <Col md={3}>Expected Salary: {currentExperience?.expectedSalary}</Col>
                        </Row>
                      </div>
                    )
                  }
                )}
              </div>
            )}

            {data?.internshipRecentExperience?.internshipRecentExperience?.length > 0 && (
              <div>
                <h5 className='mt-5'>Recent Experience Details</h5>

                {data?.internshipRecentExperience?.internshipRecentExperience?.map(
                  (recentExperience: any, index: number) => {
                    return (
                      <div key={index}>
                        <h5 className='mt-2'>Recent Experience {index + 1}</h5>
                        <Row className='mt-5'>
                          <Col md={3}>Organization Name: {recentExperience?.organizationName}</Col>
                          <Col md={3}>Designation: {recentExperience?.designation}</Col>
                          <Col md={3}>
                            Functional Area:{' '}
                            {functionalAreaList.filter(
                              (d: any) => d.id === recentExperience.functionalAreaId
                            )[0]?.name || 'N/A'}
                          </Col>
                          <Col md={3}>Salary Per Month: {recentExperience?.salaryPerMonth}</Col>
                        </Row>
                        <Row className='mt-5'>
                          <Col md={3}>From Date: {recentExperience?.fromDate}</Col>
                          <Col md={3}>To Date: {recentExperience?.toDate}</Col>
                          <Col md={3}>
                            Job Responsibilities: {recentExperience?.jobResponsibilities}
                          </Col>
                          <Col md={3}>Total Month: {recentExperience?.totalMonth}</Col>
                        </Row>
                        <Row className='mt-5'>
                          <Col md={3}>Reason for leaving: {recentExperience?.reasonForLeaving}</Col>
                        </Row>
                      </div>
                    )
                  }
                )}
              </div>
            )}

            {data?.internshipWorkedHereDetail?.position && (
              <>
                <h5 className='mt-5'>Worked Here Details</h5>
                <Row className='mt-5'>
                  <Col md={3}>
                    Branch Name:{' '}
                    {branchList.filter(
                      (d) => d.id === data?.internshipWorkedHereDetail?.branchId
                    )[0]?.title || 'N/A'}
                  </Col>
                  <Col md={3}>
                    Department:{' '}
                    {departmentList.filter(
                      (d) => d.id === data.internshipWorkedHereDetail?.departmentId
                    )[0]?.name || 'N/A'}
                  </Col>
                  <Col md={3}>Position: {data?.internshipWorkedHereDetail?.position}</Col>
                  <Col md={3}>From Date: {data?.internshipWorkedHereDetail?.fromDate}</Col>
                </Row>
                <Row className='mt-5'>
                  <Col md={3}>To Date: {data?.internshipWorkedHereDetail?.toDate}</Col>
                  <Col md={3}>Status: {data?.internshipWorkedHereDetail?.status}</Col>
                </Row>
              </>
            )}

            {data?.internshipTraining?.internshipTraining?.length > 0 && (
              <div>
                <h5 className='mt-5'>Training Details</h5>
                {data?.internshipTraining?.internshipTraining?.map(
                  (training: any, index: number) => {
                    return (
                      <div key={index}>
                        <h5 className='mt-2'>Training {index + 1}</h5>
                        <Row className='mt-5'>
                          <Col md={3}>Training Name: {training?.name}</Col>
                          <Col md={3}>Institute Name : {training?.instituteName}</Col>
                          <Col md={3}>Start Date: {training?.startDate || 'N/A'}</Col>
                          <Col md={3}>End Date: {training?.endDate || 'N/A'}</Col>
                        </Row>
                      </div>
                    )
                  }
                )}
              </div>
            )}

            {data?.internshipProfessionalReference?.internshipProfessionalReference?.length > 0 && (
              <div>
                <h5 className='mt-5'>Reference Details</h5>
                {data?.internshipProfessionalReference?.internshipProfessionalReference?.map(
                  (reference: any, index: number) => {
                    return (
                      <div key={index}>
                        <h5 className='mt-2'>Reference {index + 1}</h5>
                        <Row className='mt-5'>
                          <Col md={3}>Name: {reference?.name}</Col>
                          <Col md={3}>Address: {reference?.address}</Col>
                          <Col md={3}>Email: {reference?.email}</Col>
                          <Col md={3}>Phone Number: {reference?.phoneNumber}</Col>
                        </Row>
                        <Row className='mt-5'>
                          <Col md={3}>Organization Name: {reference?.organizationName}</Col>
                        </Row>
                      </div>
                    )
                  }
                )}
              </div>
            )}

            <h5 className='mt-5'>Document Details</h5>
            <Row className='mt-5'>
              <Col md={3}>
                Latest Photo:{' '}
                <a
                  target='_blank'
                  href={imageBaseUrl + '/' + data?.internshipDocument?.latestPhoto}
                >
                  View
                </a>
              </Col>
              <Col md={3}>
                Resume:{' '}
                <a target='_blank' href={imageBaseUrl + '/' + data?.internshipDocument?.resume}>
                  View
                </a>
              </Col>
              <Col md={3}>
                Citizenship:{' '}
                <a
                  target='_blank'
                  href={imageBaseUrl + '/' + data?.internshipDocument?.frontCitizenship}
                >
                  View
                </a>
              </Col>

              <Col md={3}>
                Application:{' '}
                <a
                  target='_blank'
                  href={imageBaseUrl + '/' + data?.internshipDocument?.application}
                >
                  View
                </a>
              </Col>
              <Col md={3} className='mt-4'>
                Recommendation Letter:{' '}
                <a
                  target='_blank'
                  href={imageBaseUrl + '/' + data?.internshipDocument?.recommendationLetter}
                >
                  View
                </a>
              </Col>
            </Row>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  )
}
export default ViewCandidateDetails
