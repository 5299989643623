import {ParamsModel} from 'src/app/modules/common/Model'
import {ExtensionCounterModel, DeleteExtensionCounterModel} from '../Model'
import {actionTypes} from './constants'
import {IExtensionCounterState} from './reducer'

export const actions = {
  // get Extension Counter
  getExtensionCounter: (params: ParamsModel = {page: 1, limit: 10}) => {
    return {
      type: actionTypes.GET_EXTENSION_COUNTER_START,
      payload: {params},
    }
  },
  getExtensionCounterSuccess: (data: IExtensionCounterState) => ({
    type: actionTypes.GET_EXTENSION_COUNTER_SUCCESS,
    payload: data,
  }),
  getExtensionCounterError: (data: IExtensionCounterState) => ({
    type: actionTypes.GET_EXTENSION_COUNTER_FINISH,
    payload: data,
  }),

  //Add ExtensionCounter Action
  CreateExtensionCounter: (data: any) => ({
    type: actionTypes.ADD_EXTENSION_COUNTER_REQUEST,
    payload: data,
  }),

  createExtensionCounterSuccess: (task: any) => ({
    type: actionTypes.ADD_EXTENSION_COUNTER_SUCCESS,
    payload: task,
  }),
  createExtensionCounterFinish: (errorMsg: any) => ({
    type: actionTypes.ADD_EXTENSION_COUNTER_FINISH,
    payload: errorMsg,
  }),
  createExtensionCounterReset: () => ({
    type: actionTypes.ADD_EXTENSION_COUNTER_RESET,
  }),

  activateExtensionCounter: (data: any) => ({
    type: actionTypes.ACTIVATE_EXTENSION_COUNTER_REQUEST,
    payload: {data},
  }),

  activateExtensionCounterSuccess: (task: any) => ({
    type: actionTypes.ACTIVATE_EXTENSION_COUNTER_SUCCESS,
    payload: task,
  }),
  activateExtensionCounterFinish: () => ({
    type: actionTypes.ACTIVATE_EXTENSION_COUNTER_FINISH,
  }),

  //Disable MemberType
  deactivateExtensionCounter: (data: any) => ({
    type: actionTypes.DEACTIVATE_EXTENSION_COUNTER_REQUEST,
    payload: {data},
  }),

  deactivateExtensionCounterSuccess: (task: any) => ({
    type: actionTypes.DEACTIVATE_EXTENSION_COUNTER_SUCCESS,
    payload: task,
  }),
  deactivateExtensionCounterFinish: () => ({
    type: actionTypes.DEACTIVATE_EXTENSION_COUNTER_FINISH,
  }),

  //Enable MemberType
  singleActivateExtensionCounter: (data: any) => ({
    type: actionTypes.SINGLE_ACTIVATE_EXTENSION_COUNTER_REQUEST,
    payload: {data},
  }),

  singleActivateExtensionCounterSuccess: (task: any) => ({
    type: actionTypes.SINGLE_ACTIVATE_EXTENSION_COUNTER_SUCCESS,
    payload: task,
  }),
  singleActivateExtensionCounterFinish: () => ({
    type: actionTypes.SINGLE_ACTIVATE_EXTENSION_COUNTER_FINISH,
  }),

  //Disable MemberType
  singleDeactivateExtensionCounter: (data: any) => ({
    type: actionTypes.SINGLE_DEACTIVATE_EXTENSION_COUNTER_REQUEST,
    payload: {data},
  }),

  singleDeactivateExtensionCounterSuccess: (task: any) => ({
    type: actionTypes.SINGLE_DEACTIVATE_EXTENSION_COUNTER_SUCCESS,
    payload: task,
  }),
  singleDeactivateExtensionCounterFinish: () => ({
    type: actionTypes.SINGLE_DEACTIVATE_EXTENSION_COUNTER_FINISH,
  }),

  // update ExtensionCounter
  updateExtensionCounter: (data: ExtensionCounterModel, id: string) => ({
    type: actionTypes.UPDATE_EXTENSION_COUNTER_START,
    payload: {data, id},
  }),

  //delete and reset ExtensionCounter
  deleteExtensionCounter: (data: DeleteExtensionCounterModel[]) => ({
    type: actionTypes.DELETE_EXTENSION_COUNTER_START,
    payload: {extensioncounter: data},
  }),

  //Get Extension Counter file csv
  getExtensionCounterFile: (fileType: string = 'csv') => {
    return {
      type: actionTypes.GET_EXTENSION_COUNTER_FILE_START,
      payload: fileType,
    }
  },

  geExtensionCounterFileSuccess: (data: IExtensionCounterState) => ({
    type: actionTypes.GET_EXTENSION_COUNTER_FILE_SUCCESS,
    payload: data,
  }),
  getExtensionCounterFileError: (data: IExtensionCounterState) => ({
    type: actionTypes.GET_EXTENSION_COUNTER_FILE_FINISH,
    payload: data,
  }),

  // IMPORT Extension Counter
  importExtensionCounter: (data: any) => ({
    type: actionTypes.IMPORT_EXTENSION_COUNTER_START,
    payload: data,
  }),
  importExtensionCounterSuccess: (task: any) => ({
    type: actionTypes.IMPORT_EXTENSION_COUNTER_SUCCESS,
    payload: task,
  }),
  importExtensionCounterFinish: () => ({
    type: actionTypes.IMPORT_EXTENSION_COUNTER_FINISH,
  }),

  //Export Template
  exportTemplateFile: () => {
    return {
      type: actionTypes.EXPORT_EXTENSION_COUNTER_TEMPLATE_FILE_START,
    }
  },

  exportTemplateFileSuccess: (data: any) => ({
    type: actionTypes.EXPORT_EXTENSION_COUNTER_TEMPLATE_FILE_SUCCESS,
    payload: data,
  }),
  exportTemplateFileFinish: () => ({
    type: actionTypes.EXPORT_EXTENSION_COUNTER_TEMPLATE_FILE_FINISH,
  }),
}
