import {Action} from 'redux'
import {ExtensionCounterModel} from '../Model'
import {actionTypes} from './constants'

export interface ActionWithPayload<T> extends Action {
  payload?: T
}

const initialExtensionCounterState: IExtensionCounterState = {
  data: {
    extensioncounter: [],
    meta: {},
  },
  loading: false,
  success: false,
  importSuccess: false,
  editSuccess: false,
  activateSuccess: false,
  deactivateSuccess: false,
  deleteSuccess: false,
  toggleLoading: false,
  singleActivateSuccess: false,
  singleDeactivateSuccess: false,
}

export interface IExtensionCounterState {
  data: {
    extensioncounter: {[key: string]: number | string}[]
    meta: {[key: string]: number}
  }

  loading: false
  success: false
  importSuccess: false
  editSuccess: false
  activateSuccess: false
  deactivateSuccess: false
  deleteSuccess: false
  toggleLoading: false
  singleActivateSuccess: false
  singleDeactivateSuccess: false
}

export const reducer = (
  state: IExtensionCounterState = initialExtensionCounterState,
  action: ActionWithPayload<IExtensionCounterState>
) => {
  switch (action.type) {
    //Get extensioncounter Reducer
    case actionTypes.GET_EXTENSION_COUNTER_START: {
      return {...state, loading: true}
    }

    case actionTypes.GET_EXTENSION_COUNTER_SUCCESS: {
      return {...state, data: action.payload?.data, loading: false}
    }

    case actionTypes.GET_EXTENSION_COUNTER_FINISH: {
      const error = action.payload
      return {...state, error, loading: false}
    }

    //export Extension Counter file
    case actionTypes.GET_EXTENSION_COUNTER_FILE_START: {
      return {...state, loading: true}
    }

    case actionTypes.GET_EXTENSION_COUNTER_FILE_SUCCESS: {
      return {...state, data: action.payload?.data, loading: false}
    }

    case actionTypes.GET_EXTENSION_COUNTER_FILE_FINISH: {
      const error = action.payload
      return {...state, error, loading: false}
    }

    //Import extension counter file
    case actionTypes.IMPORT_EXTENSION_COUNTER_START: {
      return {...state, loading: true}
    }

    case actionTypes.IMPORT_EXTENSION_COUNTER_SUCCESS: {
      return {
        ...state,
        data: action.payload,
        loading: false,
        importSuccess: true,
      }
    }

    case actionTypes.IMPORT_EXTENSION_COUNTER_FINISH: {
      const error = action.payload
      return {...state, error, loading: false, importSuccess: false}
    }

    //Add extensioncounter Reducer
    case actionTypes.ADD_EXTENSION_COUNTER_REQUEST: {
      return {...state, loading: true}
    }

    case actionTypes.ADD_EXTENSION_COUNTER_SUCCESS: {
      const bannerData = action.payload
      return {...state, success: true, loading: false}
    }

    case actionTypes.ADD_EXTENSION_COUNTER_FINISH: {
      const error = action.payload
      return {...state, error, success: false, loading: false}
    }

    case actionTypes.ADD_EXTENSION_COUNTER_RESET: {
      return {...state, success: false, data: [], loading: false}
    }

    //update extensioncounter
    case actionTypes.UPDATE_EXTENSION_COUNTER_START: {
      return {...state, loading: true}
    }

    case actionTypes.UPDATE_EXTENSION_COUNTER_SUCCESS: {
      return {
        ...state,
        data: action?.payload?.data,
        success: true,
        loading: false,
      }
    }

    case actionTypes.UPDATE_EXTENSION_COUNTER_FINISH: {
      const error = action.payload
      return {...state, error, loading: false}
    }

    case actionTypes.UPDATE_EXTENSION_COUNTER_RESET: {
      return {
        ...state,
        data: [],
        success: false,
      }
    }
    //Delete extensioncounter
    case actionTypes.DELETE_EXTENSION_COUNTER_START: {
      return {...state, loading: true, deleteSuccess: false}
    }

    case actionTypes.DELETE_EXTENSION_COUNTER_SUCCESS: {
      return {
        ...state,
        deleteSuccess: true,
        loading: false,
      }
    }

    case actionTypes.DELETE_EXTENSION_COUNTER_FINISH: {
      return {
        ...state,
        loading: false,
        deleteSuccess: false,
      }
    }

    //Activate extensioncounter
    case actionTypes.ACTIVATE_EXTENSION_COUNTER_REQUEST: {
      return {...state, loading: true}
    }

    case actionTypes.ACTIVATE_EXTENSION_COUNTER_SUCCESS: {
      return {...state, activateSuccess: true, loading: false}
    }
    case actionTypes.ACTIVATE_EXTENSION_COUNTER_FINISH: {
      return {...state, activateSuccess: false, loading: false}
    }

    //Single Activate extensioncounter
    case actionTypes.SINGLE_ACTIVATE_EXTENSION_COUNTER_REQUEST: {
      return {...state, toggleLoading: true}
    }

    case actionTypes.SINGLE_ACTIVATE_EXTENSION_COUNTER_SUCCESS: {
      const changedData: any = action.payload?.data?.extensioncounter
      let newData = state?.data?.extensioncounter?.map((data) => {
        if (data?.id === changedData[0]?.id) {
          return {
            ...data,
            status: !data?.status,
          }
        } else {
          return data
        }
      })

      return {
        ...state,
        data: {...state, meta: state?.data?.meta, extensioncounter: newData},
        singleActivateSuccess: true,
        toggleLoading: false,
      }
    }
    case actionTypes.SINGLE_ACTIVATE_EXTENSION_COUNTER_FINISH: {
      return {...state, singleActivateSuccess: false, toggleLoading: false}
    }

    //Deactivate extensioncounter
    case actionTypes.DEACTIVATE_EXTENSION_COUNTER_REQUEST: {
      return {...state, loading: true}
    }

    case actionTypes.DEACTIVATE_EXTENSION_COUNTER_SUCCESS: {
      return {...state, deactivateSuccess: true, loading: false}
    }

    case actionTypes.DEACTIVATE_EXTENSION_COUNTER_FINISH: {
      return {...state, deactivateSuccess: false, loading: false}
    }

    case actionTypes.SINGLE_DEACTIVATE_EXTENSION_COUNTER_REQUEST: {
      return {...state, toggleLoading: true}
    }

    case actionTypes.SINGLE_DEACTIVATE_EXTENSION_COUNTER_SUCCESS: {
      const changedData: any = action.payload?.data?.extensioncounter
      let newData = state?.data?.extensioncounter?.map((data) => {
        if (data?.id === changedData[0]?.id) {
          return {
            ...data,
            status: !data?.status,
          }
        } else {
          return data
        }
      })

      return {
        ...state,
        data: {...state, meta: state?.data?.meta, extensioncounter: newData},
        singleDeactivateSuccess: true,
        toggleLoading: false,
      }
    }

    case actionTypes.SINGLE_DEACTIVATE_EXTENSION_COUNTER_FINISH: {
      return {...state, singleDeactivateSuccess: false, toggleLoading: false}
    }
    case actionTypes.EXPORT_EXTENSION_COUNTER_TEMPLATE_FILE_START: {
      return {...state, loading: true}
    }

    case actionTypes.EXPORT_EXTENSION_COUNTER_TEMPLATE_FILE_SUCCESS: {
      return {...state, templateExport: true, loading: false}
    }

    case actionTypes.EXPORT_EXTENSION_COUNTER_TEMPLATE_FILE_FINISH: {
      const error = action.payload
      return {...state, error, templateExport: false, loading: false}
    }

    default:
      return state
  }
}
