import {Dispatch, SetStateAction} from 'react'
import {ReactSortable} from 'react-sortablejs'
import Modal from 'rsuite/Modal'
import {ItemType} from '../Model'

interface Props {
  isOpen?: boolean
  handleClose: () => void
  data: Array<ItemType>
  setSortData: Dispatch<SetStateAction<Array<ItemType>>>
  setSortInnerData: (data: Array<any>) => void
  setSortInnerMostData: (data: Array<any>) => void
  setSortInnerMostChildData: (
    data: Array<any>,
    index1: number,
    index2: number,
    index3: number
  ) => void

  sortButtonName: string | undefined
  handleSubmitSort: ((data: Array<ItemType>) => void) | undefined
  loading: boolean | undefined
}
const SortModal = ({
  isOpen,
  handleClose,
  data,
  setSortData,
  setSortInnerData,
  setSortInnerMostData,
  setSortInnerMostChildData,
  sortButtonName,
  handleSubmitSort,
  loading,
}: Props) => {
  return (
    <>
      <Modal
        backdrop='static'
        role='alertdialog'
        open={isOpen}
        size='sm'
        onClose={handleClose}
        enforceFocus={false}
        data-cy='modal'
      >
        <Modal.Header>
          <Modal.Title data-cy='modal-title'>{sortButtonName}</Modal.Title>
        </Modal.Header>
        <Modal.Body className='mb-3'>
          <div style={{height: '65vh', overflowY: 'auto'}}>
            <ReactSortable list={data} setList={setSortData}>
              {data?.map((item: ItemType, index1: number) => (
                <>
                  <div
                    className='btn btn-primary cursor-move rounded-0 w-100 mb-1'
                    key={item?.id}
                    data-cy={`${item?.name}-button`}
                  >
                    {item?.name ? item?.name : item?.title}

                    <ReactSortable list={item?.children || []} setList={setSortInnerData}>
                      {item?.children &&
                        item?.children.map((child: any, index2: number) => (
                          <div
                            className='btn bg-secondary btn-primary cursor-move rounded-0 w-100 mb-1'
                            key={child?.id}
                            data-cy={`${child?.tableViewName}-button`}
                          >
                            {child?.tableViewName ? child?.tableViewName : child?.tableViewName}
                            <ReactSortable
                              list={child?.children || []}
                              setList={setSortInnerMostData}
                            >
                              {child?.children &&
                                child?.children.map((items: any, index3: number) => (
                                  <div
                                    className='btn bg-secondary btn-primary cursor-move rounded-0 w-100 mb-1'
                                    key={items?.id}
                                    data-cy={`${items?.tableViewName}-button`}
                                  >
                                    {items?.tableViewName
                                      ? items?.tableViewName
                                      : items?.tableViewName}
                                    <ReactSortable
                                      list={items?.children || []}
                                      setList={(data) =>
                                        setSortInnerMostChildData(data, index1, index2, index3)
                                      }
                                      data-cy='sortable-table'
                                    >
                                      {items?.children &&
                                        items?.children.map((grandChild: any) => (
                                          <div
                                            className='btn bg-secondary btn-primary cursor-move rounded-0 w-100 mb-1'
                                            key={grandChild?.id}
                                            data-cy={`${grandChild?.tableViewName}-button`}
                                          >
                                            {grandChild?.tableViewName
                                              ? grandChild?.tableViewName
                                              : grandChild?.tableViewName}
                                          </div>
                                        ))}
                                    </ReactSortable>
                                  </div>
                                ))}
                            </ReactSortable>
                          </div>
                        ))}
                    </ReactSortable>
                  </div>
                </>
              ))}
            </ReactSortable>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button
            className={`btn btn-primary btn-sm ms-3 ${loading ? 'disabled' : ''}`}
            disabled={loading}
            onClick={() => handleSubmitSort && handleSubmitSort(data)}
            data-cy='modal-submit'
          >
            Save
          </button>
          <button
            onClick={handleClose}
            className='btn btn-secondary btn-sm ms-3'
            data-cy='modal-cancel'
          >
            Cancel
          </button>
        </Modal.Footer>
      </Modal>
    </>
  )
}

export default SortModal
